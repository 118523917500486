import React from "react";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import "./KycDetailsItem.scss";

const KycDetailsItem = ({ index, el, date }) => {
  const navigate = useNavigate();
  const wrapperClass = `kyc-details-item__wrapper ${
    index % 2 === 0 ? "kyc-details-item" : "kyc-details-item__dark"
  }`;

  return (
    <div className={wrapperClass}>
      <span className="kyc-details-item__element">
        {capitalizeFirstLetter(el?.status)}
      </span>

      <span
        onClick={() => navigate(`/user/${el?.id}`)}
        className="kyc-details-item__element kyc-details-item__element-justify-center kyc-details-item__element-link"
      >
        {el?.email}
      </span>
    </div>
  );
};

export default KycDetailsItem;
