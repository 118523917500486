import React, { useEffect, useState } from "react";
import "./UserComands.scss";
import Select from "react-select";

import webSocketService from "../../services/WebSocketService";
import TotalRangePicker from "../TotalRangePicker/TotalRangePicker";
import {
  endOfMonth,
  formatISO,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import {
  commandsStatuses,
  customStylesSelect,
  totalsRanges,
} from "../../utils/constants/constants";
import CommandItem from "./CommandItem/CommandItem";
import CommandsLegend from "./CommandsLegend/CommandsLegend";
import Button from "../Button/Button";
import { PulseLoader } from "react-spinners";

const UserComands = ({ userId }) => {
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const [selectorValue, setSelectorValue] = useState("past7");
  const [selectorValueType, setSelectorValueType] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(50);
  const [loading, setLoading] = useState(true);
  const itemsPerLoad = 50;
  const dataToShow = filteredData?.slice(0, visibleCount) || [];
  const handleLoadMore = () => {
    setVisibleCount((prev) => prev + itemsPerLoad);
  };

  const handleLoadLess = () => {
    setVisibleCount((prev) => prev - itemsPerLoad);
  };

  const filterByType = (commandsData) => {
    switch (selectorValueType) {
      case "success":
        setFilteredData(
          commandsData?.filter((el) => {
            return el?.response?.code === 200;
          }),
        );
        break;
      case "error":
        setFilteredData(
          commandsData?.filter((el) => el?.response?.code !== 200),
        );
        break;
      default:
        setFilteredData(commandsData);
        break;
    }
  };
  const getCommands = async (from, to) => {
    setLoading(true);
    const commandsData = await webSocketService.sendMessage("getFlow", "c", {
      userId,
      from: formatISO(new Date(from)).slice(0, 19) + "Z",
      to: formatISO(new Date(to)).slice(0, 19) + "Z",
    });

    setData(commandsData);
    filterByType(commandsData);
    setLoading(false);
  };

  const getDateRange = (value) => {
    const today = new Date();
    today.setMinutes(today.getMinutes() + today.getTimezoneOffset());
    let startDate, endDate;

    switch (value) {
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = today;
        break;
      case "lastMonth":
        const lastMonthDate = subMonths(today, 1);
        startDate = startOfMonth(lastMonthDate);
        endDate = endOfMonth(lastMonthDate);
        break;
      case "past7":
        startDate = subDays(today, 7);
        endDate = today;
        break;
      case "past30":
        startDate = subDays(today, 30);
        endDate = today;
        break;
      case "past90":
        startDate = subDays(today, 90);
        endDate = today;
        break;
      case "all":
        startDate = new Date(2023, 0, 1);
        endDate = today;
        break;

      case "customized":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = null;
        endDate = null;
    }
    setFromDate(startDate);
    setToDate(endDate);
    return { startDate, endDate };
  };

  useEffect(() => {
    getCommands(fromDate, toDate, true);
    setIsLoaded(true);
  }, [userId]);
  useEffect(() => {
    setVisibleCount(50);
  }, [filteredData]);

  useEffect(() => {
    if (isLoaded) {
      if (selectorValue === "customized") {
        setSelectorValue("customized");
        return setShowRangePicker(true);
      }

      if (selectorValue) {
        const range = getDateRange(selectorValue);
        getCommands(range.startDate, range.endDate);
      }
    }
  }, [selectorValue]);

  useEffect(() => {
    if (isLoaded) {
      filterByType(data);
    }
  }, [selectorValueType]);

  return !loading ? (
    <div className="commands__wrapper">
      <div className="commands__filters">
        <Select
          styles={customStylesSelect}
          options={totalsRanges}
          placeholder={"placeholder"}
          getOptionValue={(option) => option.value}
          onChange={(option) => {
            if (
              option.value === "customized" &&
              selectorValue === "customized"
            ) {
              setShowRangePicker(true);
            }

            setSelectorValue(option.value);
          }}
          value={totalsRanges.find((option) => option.value === selectorValue)}
        />
        <div className="commands__indent" />
        <Select
          styles={customStylesSelect}
          options={commandsStatuses}
          placeholder={"All"}
          getOptionValue={(option) => option.value}
          onChange={(option) => {
            setSelectorValueType(option.value);
          }}
          value={commandsStatuses.find(
            (option) => option.value === selectorValueType,
          )}
        />
      </div>

      <CommandsLegend />

      {dataToShow?.map((el) => (
        <CommandItem el={el} key={el?.created + el?.function} />
      ))}

      <span className="user__ip-button" onClick={handleLoadMore}>
        Show more
      </span>
      {filteredData.slice(0, visibleCount)?.length > 50 ? (
        <span className="user__ip-button" onClick={handleLoadLess}>
          Show less
        </span>
      ) : null}

      {showRangePicker && (
        <TotalRangePicker
          showModal={showRangePicker}
          setShowModal={setShowRangePicker}
          getData={getCommands}
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
    </div>
  ) : (
    <div className="commands__loader">
      <PulseLoader color="#3d127d" loading={loading} size={10} />
    </div>
  );
};

export default UserComands;
