import {
  endOfMonth,
  formatISO,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { useState } from "react";
import webSocketService from "../../../../../services/WebSocketService";

const useMonetization = () => {
  const filterTypeSaved = localStorage.getItem("filterTypeMonetization");
  const fromSaved = localStorage.getItem("fromMonetization");
  const toSaved = localStorage.getItem("toMonetization");
  const savedSelectorValue = filterTypeSaved || "past7";
  const [data, setData] = useState([]);
  const [pureData, setPureData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const [selectorValue, setSelectorValue] = useState(savedSelectorValue);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [balancesData, setBalancesData] = useState([]);

  const groupByDate = (data) => {
    return Object.values(
      data.reduce((acc, item) => {
        const date = formatISO(parseISO(item.created), {
          representation: "date",
        });

        if (!acc[date]) {
          acc[date] = { date, transactions: [] };
        }

        acc[date].transactions.push(item);

        return acc;
      }, {}),
    );
  };
  const getMonetizationData = async (from, to) => {
    let body = {
      type: "monetization",
      subType: "custodial",
      from: formatISO(new Date(from)).slice(0, 19) + "Z",
      to: formatISO(new Date(to)).slice(0, 19) + "Z",
      groupBy: "date",
    };

    const monetizationData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      body,
    );

    const balances = await webSocketService.sendMessage("getCorporate", "c");

    if (typeof totals === "string") {
      setData([]);
    } else {
      const sortedData = monetizationData.sort(
        (a, b) => new Date(b.created) - new Date(a.created),
      );
      localStorage.setItem("filterTypeMonetization", selectorValue);
      localStorage.setItem("fromMonetization", from);
      localStorage.setItem("toMonetization", to);
      setPureData(sortedData);
      setData(groupByDate(sortedData));
      setBalancesData(balances);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const getTBCTableCSV = async (from, to) => {
    let body = {
      type: "monetization",
      subType: "lightning",
      from: formatISO(new Date(from)).slice(0, 19) + "Z",
      to: formatISO(new Date(to)).slice(0, 19) + "Z",
      groupBy: "date",
    };

    const monetizationData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      body,
    );

    if (typeof totals === "string") {
      return [];
    }
    const resultTable = [];

    const sortedData = monetizationData.sort(
      (a, b) => new Date(b.created) - new Date(a.created),
    );

    sortedData?.forEach((el) => {
      resultTable?.push({
        created: el?.created,
        id: el?.id,
        invoiceAmount: el?.invoice?.amount,
        recipientAmount: el?.recipient?.amount,
        recipientCurrency: el?.recipient?.currency,
        revenueBTC: el?.revenue?.BTC,
        revenueUSD: el?.revenue?.USD,
      });
    });

    return resultTable;
  };

  const getDateRange = (value) => {
    const today = new Date();
    today.setMinutes(today.getMinutes() + today.getTimezoneOffset());
    let startDate, endDate;

    switch (value) {
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = today;
        break;
      case "lastMonth":
        const lastMonthDate = subMonths(today, 1);
        startDate = startOfMonth(lastMonthDate);
        endDate = endOfMonth(lastMonthDate);
        break;
      case "past7":
        startDate = subDays(today, 7);
        endDate = today;
        break;
      case "past30":
        startDate = subDays(today, 30);
        endDate = today;
        break;
      case "past90":
        startDate = subDays(today, 90);
        endDate = today;
        break;
      case "all":
        startDate = new Date(2023, 0, 1);
        endDate = today;
        break;
      case "customized":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = null;
        endDate = null;
    }
    setFromDate(startDate);
    setToDate(endDate);
    return { startDate, endDate };
  };
  return {
    filterTypeSaved,
    fromSaved,
    toSaved,
    fromDate,
    toDate,
    isLoaded,
    selectorValue,
    isLoading,
    pureData,
    balancesData,
    data,
    showRangePicker,
    setSelectorValue,
    getMonetizationData,
    setIsLoaded,
    setShowRangePicker,
    getDateRange,
    setFromDate,
    setToDate,
    getTBCTableCSV,
  };
};

export default useMonetization;
