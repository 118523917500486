import React, { useEffect, useState } from "react";
import "./KycDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { formatISO, endOfDay } from "date-fns";
import webSocketService from "../../services/WebSocketService";
import StatisticTable from "../../components/StatisticTable/StatisticTable";
import useTableContent from "./hooks/useTableContent";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import parseDate from "../../utils/parseDate";
import KycDetailsLegend from "../../components/KycDetailsLegend/KycDetailsLegend";
import KycDetailsItem from "../../components/KycDetailsItem/KycDetailsItem";

const KycDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pendingTable, passedTable, totalTable } = useTableContent(data);

  const navigate = useNavigate();
  const location = useLocation();

  const { date } = location.state || {};

  const dateSplitted = date.split("-");
  const dateObj = new Date(
    dateSplitted[0],
    +dateSplitted[1] - 1,
    dateSplitted[2],
  );

  const endOfDayObj = endOfDay(dateObj);

  const getKYCData = async () => {
    let body = {
      type: "registrations",
      from: formatISO(new Date(dateObj)).slice(0, 19) + "Z",
      to: formatISO(new Date(endOfDayObj)).slice(0, 19) + "Z",
      groupBy: "user",
      currencies: ["USD", "BTC"],
    };

    const KYCData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      body,
    );

    if (typeof totals === "string") {
      setData([]);
    } else {
      setData(KYCData);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    getKYCData();
  }, []);

  if (isLoading) {
    return (
      <div className="kyc-details__loader">
        <MoonLoader
          color="#7236CD"
          loading={isLoading}
          cssOverride={loaderOverride}
          size={35}
        />
      </div>
    );
  }
  return (
    <div className="kyc-details__wrapper">
      <div className="kyc-details__content">
        <Header withLogo navigate={navigate} />
        <div className="kyc-details__top-block">
          <span className="kyc-details__label">{parseDate(date)}</span>
        </div>
        <div className="kyc-details__tables">
          <StatisticTable data={totalTable} />
          <StatisticTable data={pendingTable} />
          <StatisticTable data={passedTable} />
        </div>

        <div className="kyc-table">
          <KycDetailsLegend />
          {data?.users
            ? data?.users?.map((el) => <KycDetailsItem el={el} key={el[0]} />)
            : null}
        </div>
      </div>
      <Footer navigate={navigate} />
    </div>
  );
};

export default KycDetails;
