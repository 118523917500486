import { calculateDecimal } from "../../../../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../../../../utils/cutWithoutRounding";
import { showNumberWithDelimiters } from "../../../../../utils/showNumberWithDelimiters";

const useTableContent = (pureData, dataACH, dataWire) => {
  const totalTable = [
    {
      key: "Total Withdrawals",
      value: `$${showNumberWithDelimiters(
        cutWithoutRounding(
          calculateDecimal(
            pureData?.total?.data?.USD?.amount ?? 0,
            pureData?.total?.data?.BTC?.value ?? 0,
            "add",
          ),
          2,
        ),
      )}`,
    },
    {
      key: "Count",
      value:
        (pureData?.total?.data?.USD?.count ?? 0) +
        (pureData?.total?.data?.BTC?.count ?? 0),
    },
  ];
  const USDTable = [
    {
      key: "Total USD",
      value: `$${
        showNumberWithDelimiters(
          cutWithoutRounding(pureData?.total?.data?.USD?.amount, 2),
        ) || 0
      }`,
    },
    {
      key: "USD Count",
      value: pureData?.total?.data?.USD?.count,
    },
  ];
  const USDWireTable = [
    {
      key: "Total USD Wire",
      value: `$${
        showNumberWithDelimiters(
          cutWithoutRounding(dataWire?.total?.data?.USD?.amount, 2),
        ) || 0
      }`,
    },
    {
      key: "USD Wire Count",
      value: dataWire?.total?.data?.USD?.count,
    },
  ];

  const USDACHTable = [
    {
      key: "Total USD ACH",
      value: `$${
        showNumberWithDelimiters(
          cutWithoutRounding(dataACH?.total?.data?.USD?.amount, 2),
        ) || 0
      }`,
    },
    {
      key: "USD ACH Count",
      value: dataACH?.total?.data?.USD?.count,
    },
  ];
  const BTCTable = [
    {
      key: "Total BTC",
      value: `BTC ${cutWithoutRounding(pureData?.total?.data?.BTC?.amount, 8)}`,
    },
    {
      key: "BTC Count",
      value: pureData?.total?.data?.BTC?.count,
    },
  ];

  return {
    totalTable,
    USDTable,
    USDACHTable,
    USDWireTable,
    BTCTable,
  };
};

export default useTableContent;
