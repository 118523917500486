import React from "react";
import "./MonetizationDetailsLegend.scss";

const MonetizationDetailsLegend = () => {
  return (
    <div className="legend-monetiz__wrapper">
      <span className="legend-monetiz-det__element">Total</span>
      <span className="legend-monetiz-det__element">Trading</span>
      <span className="legend-monetiz-det__element">Withdraw</span>
      <span className="legend-monetiz-det__element">Transactions</span>
      <span className="legend-monetiz-det__element">User</span>
    </div>
  );
};

export default MonetizationDetailsLegend;
