import React from "react";
import "./TradeLegend.scss";

const TradeLegend = () => {
  return (
    <div className="legend-trade__wrapper">
      <span className="legend-trade__element">Date</span>
      <span className="legend-trade__element">BTC Buys (T/C)</span>
      <span className="legend-trade__element">BTC Sells (T/C)</span>
      
    </div>
  );
};

export default TradeLegend;
