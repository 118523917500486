import React, { useEffect, useState } from "react";
import "./VolumeDetails.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import webSocketService from "../../services/WebSocketService";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import VolumeDatailsItem from "../../components/VolumeDatailsItem/VolumeDatailsItem";
import VolumeDetailsLegend from "../../components/VolumeDetailsLegend/VolumeDetailsLegend";
import parseDate from "../../utils/parseDate";
import { getTotalVolumeTransactions } from "../../utils/getTotalVolumeTransactions";
import { formatISO } from "date-fns";

const VolumeDetails = () => {
  const navigate = useNavigate();
  const { date } = useParams();

  const [detailsData, setDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDetailsData = async (from) => {
    const to = from?.replace("00:00:00", "23:59:59");
    const body = {
      type: "transactions",
      from: date,
      to: to,
      groupBy: "user",
    };

    const details = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      body,
    );

    if (typeof details === "string") {
      setDetailsData([]);
    } else {
      setDetailsData(details);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDetailsData(date);
  }, []);

  return !isLoading ? (
    <div className="totals__wrapper">
      <div className="totals__content">
        <Header withLogo navigate={navigate} />

        <div className="totals__top-block">
          <span className="totals__label">{parseDate(date)}</span>
          <span className="totals__volume">
            Total Volume:
            <span className="totals__volume totals__bold">
              {` $ ${getTotalVolumeTransactions(detailsData)}`}
            </span>
          </span>
        </div>

        <VolumeDetailsLegend />
        {detailsData?.map((el, index) => (
          <VolumeDatailsItem key={el?.volume + index} index={index} item={el} />
        ))}
      </div>
      <Footer navigate={navigate} />
    </div>
  ) : (
    <div className="totals__loader">
      <MoonLoader
        color="#7236CD"
        loading={isLoading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default VolumeDetails;
