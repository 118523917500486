import { calculateDecimal } from "../../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../../utils/cutWithoutRounding";
import { showNumberWithDelimiters } from "../../../utils/showNumberWithDelimiters";

const useTableContent = (onChain, sendCash, payments) => {
  const totalTable = [
    {
      key: "Total LN Sends",
      value: `LN ${cutWithoutRounding(
        calculateDecimal(
          calculateDecimal(
            payments?.total?.data?.LN?.amount || 0,
            sendCash?.total?.data?.LN?.amount || 0,
            "add",
          ),
          onChain?.total?.data?.LN?.amount || 0,
          "add",
        ),
        8,
      )}`,
      subValue: `≈ $${showNumberWithDelimiters(
        cutWithoutRounding(
          calculateDecimal(
            calculateDecimal(
              payments?.total?.data?.LN?.value || 0,
              sendCash?.total?.data?.LN?.value || 0,
              "add",
            ),
            onChain?.total?.data?.LN?.value || 0,
            "add",
          ),
          2,
        ),
      )}`,
      description: "Using the current rate",
    },
    {
      key: "LN Sends Count",
      value:
        (payments?.total?.data?.LN?.count || 0) +
        (sendCash?.total?.data?.LN?.count || 0) +
        (onChain?.total?.data?.LN?.count || 0),
    },
  ];
  const totalRegularTable = [
    {
      key: "Total Regular Sends",
      value: `LN ${cutWithoutRounding(
        payments?.total?.data?.LN?.amount || 0,
        8,
      )}`,
      subValue: `≈ $${showNumberWithDelimiters(
        cutWithoutRounding(payments?.total?.data?.LN?.value || 0, 2),
      )}`,
      description: "Using the current rate",
    },
    {
      key: "Regular Sends Count",
      value: payments?.total?.data?.LN?.count,
    },
  ];

  const totalCashSendsTable = [
    {
      key: "Total Cash Sends",
      value: `LN ${cutWithoutRounding(
        sendCash?.total?.data?.LN?.amount || 0,
        8,
      )}`,
      subValue: `≈ $${showNumberWithDelimiters(
        cutWithoutRounding(sendCash?.total?.data?.LN?.value || 0, 2),
      )}`,
      description: "Using the current rate",
    },
    {
      key: "Cash Sends Count",
      value: sendCash?.total?.data?.LN?.count,
    },
  ];

  const totalOnChainTable = [
    {
      key: "Total On-Chain Sends",
      value: `LN ${cutWithoutRounding(
        onChain?.total?.data?.LN?.amount || 0,
        8,
      )}`,
      subValue: `≈ $${showNumberWithDelimiters(
        cutWithoutRounding(onChain?.total?.data?.LN?.value || 0, 2),
      )}`,
      description: "Using the current rate",
    },
    {
      key: "On-Chain Sends Count",
      value: onChain?.total?.data?.LN?.count,
    },
  ];

  return {
    totalTable,
    totalRegularTable,
    totalCashSendsTable,
    totalOnChainTable,
  };
};

export default useTableContent;
