import React from "react";
import "./SendsDetailsLegend.scss";

const SendsDetailsLegend = () => {
  return (
    <div className="legend-sends-details__wrapper">
      <span className="legend-sends-details__element">LN Sends (T/C)</span>
      <span className="legend-sends-details__element">Regular Sends (T/C)</span>
      <span className="legend-sends-details__element">Cash Sends (T/C)</span>
      <span className="legend-sends-details__element">On-Chain Sends</span>
    </div>
  );
};

export default SendsDetailsLegend;
