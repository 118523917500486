import React from "react";
import { useNavigate } from "react-router-dom";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import "./TradeDetailsItem.scss";

const TradeDetailsItem = ({ index, el, date }) => {
  const navigate = useNavigate();

  const wrapperClass = `trade-details-item__wrapper ${
    index % 2 === 0 ? "trade-details-item" : "trade-details-item__dark"
  }`;

  const item = el[1];
  const email = el[1]?.buy?.email || el[1]?.sell?.email;
  const buyCount = item?.buy?.data?.BTC?.count ?? 0;
  const sellCount = item?.sell?.data?.BTC?.count ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="trade-details-item__element">
        <span>
          {`BTC ${cutWithoutRounding(item?.buy?.data?.BTC?.amount || 0, 8)}`}
        </span>
        <span>{buyCount || "0"}</span>
      </span>
      <span className="trade-details-item__element">
        <span>
          {`BTC ${cutWithoutRounding(item?.sell?.data?.BTC?.amount || 0, 8)}`}
        </span>
        <span>{sellCount || "0"}</span>
      </span>

      <span
        onClick={() => navigate(`/user/${el[0]}`)}
        className="trade-details-item__element trade-details-item__element-justify-center trade-details-item__element-link"
      >
        {email}
      </span>
    </div>
  );
};

export default TradeDetailsItem;
