import React from "react";
import { useNavigate } from "react-router-dom";
import parseDate from "../../utils/parseDate";
import Button from "../Button/Button";
import "./KYCItem.scss";

const KYCItem = ({ index, el }) => {
  const navigate = useNavigate();

  const wrapperClass = `kyc-item__wrapper ${
    index % 2 === 0 ? "kyc-item" : "kyc-item__dark"
  }`;

  const item = el[1];

  return (
    <div className={wrapperClass}>
      <span className="kyc-item__element kyc-item__element-justify-center">
        {parseDate(el[0])}
      </span>
      <span className="kyc-item__element">
        <span>{item?.registered || "0"}</span>
      </span>
      <span className="kyc-item__element">
        <span>{item?.pending || "0"}</span>
      </span>
      <span className="kyc-item__element">
        <span>{item?.active || "0"}</span>
      </span>

      <Button
        wrapperStyles="kyc-item__button"
        labelStyles="kyc-item__label"
        label="Details"
        onPress={() =>
          navigate(`/kycDetails`, {
            state: {
              date: el[0],
            },
          })
        }
      />
    </div>
  );
};

export default KYCItem;
