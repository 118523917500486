import React from "react";
import "./KYCLegend.scss";

const KYCLegend = () => {
  return (
    <div className="legend-kyc__wrapper">
      <span className="legend-kyc__element">Date</span>
      <span className="legend-kyc__element">Registrations</span>
      <span className="legend-kyc__element">KYC Pending</span>
      <span className="legend-kyc__element">KYC Passed</span>
    </div>
  );
};

export default KYCLegend;
