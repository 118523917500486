import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import parseDate from "../../utils/parseDate";
import Button from "../Button/Button";
import "./SendsItem.scss";

const SendsItem = ({ index, el }) => {
  const navigate = useNavigate();

  const wrapperClass = `sends-item__wrapper ${
    index % 2 === 0 ? "sends-item" : "sends-item__dark"
  }`;

  const item = el[1];
  const paymentCount = item?.payments?.LN?.count ?? 0;
  const sendCashCount = item?.sendCash?.LN?.count ?? 0;
  const onChainCount = item?.onChain?.LN?.count ?? 0;
  const totalCount = paymentCount + sendCashCount + onChainCount ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="sends-item__element sends-item__element-justify-center">
        {parseDate(el[0])}
      </span>
      <span className="sends-item__element">
        <span>
          LN{" "}
          {cutWithoutRounding(
            calculateDecimal(
              calculateDecimal(
                item?.payments?.LN?.amount || "0",
                item?.sendCash?.LN?.amount || "0",
                "add",
              ) || "0",
              item?.onChain?.LN?.amount || "0",
              "add",
            ),
            8,
          )}
        </span>
        <span>{totalCount}</span>
      </span>
      <span className="sends-item__element">
        <span>LN {cutWithoutRounding(item?.payments?.LN?.amount || 0, 8)}</span>
        <span>{paymentCount}</span>
      </span>
      <span className="sends-item__element">
        <span>LN {cutWithoutRounding(item?.sendCash?.LN?.amount || 0, 8)}</span>
        <span>{sendCashCount}</span>
      </span>
      <span className="sends-item__element">
        <span>LN {cutWithoutRounding(item?.onChain?.LN?.amount || 0, 8)}</span>
        <span>{onChainCount}</span>
      </span>

      <Button
        wrapperStyles="sends-item__button"
        labelStyles="sends-item__label"
        label="Details"
        onPress={() =>
          navigate(`/sendsDetails`, {
            state: {
              date: el[0],
            },
          })
        }
      />
    </div>
  );
};

export default SendsItem;
