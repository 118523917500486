import React, { useState } from "react";
import "./CommandItem.scss";
import ThreeDotsVertical from "../../../assets/svgs/threeDotsVertical.svg";
import CloseIcon from "../../../assets/svgs/closeIcon.svg";

import { allExpanded, darkStyles, JsonView } from "react-json-view-lite";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/constants/constants";

const CommandItem = ({ index, el }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperClass = `command-item__wrapper ${
    index % 2 === 0 ? "command-item" : "command-item__dark"
  }`;

  const code = el?.response?.code;
  const isSuccess = code === 200;

  const onCopyCode = () => {
    toast.success("Copied to clipobard!", toastOptions);
    navigator.clipboard.writeText(JSON.stringify(el));
  };

  return (
    <div className="command-item__main-wrapper">
      <div className={wrapperClass}>
        <span className="command-item__element">{el?.function}</span>
        <span className="command-item__element command-item__element-bigger">
          {isSuccess ? code : el?.response?.data}
        </span>
        <span className="command-item__element ">
          <span
            className={`command-item__type command-item__type-${
              isSuccess ? "green" : "red"
            }`}
          >
            <span>{isSuccess ? "Success" : "Error"}</span>
          </span>
        </span>
        <div
          className="command-item__button"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <img src={CloseIcon} />
          ) : (
            <img src={ThreeDotsVertical} />
          )}
        </div>
      </div>
      {isExpanded ? (
        <div className="command-item__code-wrapper">
          <div className="command-item__code-header">
            <span>Code</span>

            <span
              className="command-item__code-copy"
              onClick={() => onCopyCode()}
            >
              Copy code
            </span>
          </div>
          <div className="command-item__code-container">
            <JsonView
              data={el}
              shouldExpandNode={allExpanded}
              style={{
                ...darkStyles,
                stringValue: "command-item__value",
                label: "command-item__key",
                quotesForFieldNames: true,
                punctuation: "command-item__punctuation",
                nullValue: "command-item__value",
                undefinedValue: "command-item__value",
                numberValue: "command-item__value",
                booleanValue: "command-item__value",
                otherValue: "command-item__value",
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CommandItem;
