import { Bounce } from "react-toastify";

export const moduleTypes = [
  { value: "trade", label: "Credit (Trade)" },
  { value: "referral", label: "Rewards (Referral)" },
];

export const currencyTypes = [
  { value: "BTC", label: "BTC" },
  { value: "USD", label: "USD" },
];

export const getModalCustomStyles = (height = 500, contentStyles = {}) => {
  return {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: height,
      width: 384,
      backgroundColor: "#fff",
      borderRadius: "10px",
      ...contentStyles,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
};

export const customStylesSelect = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    outline: "none",
    borderRadius: "4px",
    backgroundColor: "#F7F7FD",
    height: 54,
  }),
  option: (provided, state) => ({
    ...provided,
    height: 54,
    backgroundColor: "#F7F7FD",
    color: "#3D127D",
    paddingTop: 20,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#F7F7FD",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#F7F7FD",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#3D127D",
  }),
};

export const loaderOverride = {
  borderColor: "#7236CD",
};

export const transactionsFiltersList = [
  { name: "All", value: "all" },
  { name: "Deposits", value: "deposit" },
  { name: "Withdrawals", value: "withdraw" },
  { name: "Trades", value: "trade" },
  { name: "On Chain Sends", value: "onChainSend" },
  { name: "On Chain Receives", value: "onChainReceive" },
  { name: "Lightning Sends", value: "payOut" },
  { name: "Lightning Receives", value: "payIn" },
  { name: "Send Cash Transfers", value: "sendCashTransfers" },
  { name: "Invoice Transfers", value: "invoiceTransfers" },
  { name: "Credit", value: "credit" },
  { name: "Market", value: "market" },
  { name: "Rewards", value: "referral" },
  { name: "Fees", value: "fee" },
];
export const statisticFiltersList = [
  "Monetization",
  "Users",
  "Deposit",
  "Withdrawal",
  "BTC trade",
  "LN sends",
  "Referral codes",
];

export const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export const BTC_ZERO_BALANCE = ".00000000";

export const totalsRanges = [
  { value: "past7", label: "Past 7 days" },
  { value: "thisMonth", label: "This month" },
  { value: "lastMonth", label: "Last month" },
  { value: "past30", label: "Past 30 days" },
  { value: "past90", label: "Past 90 days" },
  { value: "all", label: "All time" },
  { value: "customized", label: "Custom" },
];

export const commandsStatuses = [
  { value: "", label: "All" },
  { value: "success", label: "Success" },
  { value: "error", label: "Error" },
];

export const pickerStyle = { color: "#3d127d" };

export const UFPromoUsersProd = [
  {
    email: "olblueeyescapes@gmail.com",
    id: "ef68e9a2-7a1a-4090-b0bd-f60ee99cd1a9",
  },
  {
    email: "anthony@rhinobitcoin.com",
    id: "e8d492e0-295e-4072-a37e-e748db8deeea",
  },
  {
    email: "LH@rhinobitcoin.com",
    id: "49838c40-e889-409b-bfb6-ee924707d755",
  },
  {
    email: "mjpontikes@gmail.com",
    id: "8935d69a-00ce-4c02-9c8d-fcc1a3a08c20",
  },
  {
    email: "matt@rhinobitcoin.com",
    id: "1d111f6e-3639-484b-b92e-c7b126ef9d78",
  },

  {
    email: "rob.wallace88@gmail.com ",
    id: "5d412fab-2748-4dce-aea8-c9c8755c35d0",
  },
];

export const UFPromoUsersDev = [
  { email: "modern@mailto.plus", id: "1313eb39-6bd2-41d9-9eda-b85d8e12e64f" },
  { email: "test99@mailto.plus", id: "111de0b6-8e1c-4547-aa99-adfb7d4fc722" },
];

export const columnsUsersCSV = [
  {
    id: "first",
    displayName: "Full name",
  },
  {
    id: "second",
    displayName: "Email",
  },
  {
    id: "third",
    displayName: "User ID",
  },
  {
    id: "fourth",
    displayName: "Referral code",
  },
  {
    id: "fifth",
    displayName: "Account type",
  },
  {
    id: "sixth",
    displayName: "Registration type",
  },
];

export const columnsRFQCSV = [
  {
    id: "created",
    displayName: "Created",
  },
  {
    id: "id",
    displayName: "ID",
  },
  {
    id: "invoiceAmount",
    displayName: "Invoice Amount",
  },
  {
    id: "recipientAmount",
    displayName: "Recipient Amount",
  },
  {
    id: "recipientCurrency",
    displayName: "Recipient Currency",
  },
  {
    id: "revenueBTC",
    displayName: "Revenue BTC",
  },

  {
    id: "revenueUSD",
    displayName: "Revenue USD",
  },
];
