import React from "react";
import "./MonetizationLegend.scss";

const MonetizationLegend = () => {
  return (
    <div className="legend-monetiz__wrapper">
      <span className="legend-monetiz__element">Date</span>
      <span className="legend-monetiz__element">Total</span>
      <span className="legend-monetiz__element">Trading</span>
      <span className="legend-monetiz__element">Withdraw</span>
      <span className="legend-monetiz__element">Transactions</span>
      <span className="legend-monetiz__element">Users</span>
    </div>
  );
};

export default MonetizationLegend;
