import React, { useEffect, useState } from "react";
import "./TradeDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { formatISO, endOfDay } from "date-fns";
import webSocketService from "../../services/WebSocketService";
import StatisticTable from "../../components/StatisticTable/StatisticTable";
import useTableContent from "./hooks/useTableContent";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import parseDate from "../../utils/parseDate";
import TradeDetailsLegend from "../../components/TradeDetailsLegend/TradeDetailsLegend";
import TradeDetailsItem from "../../components/TradeDetailsItem/TradeDetailsItem";

const TradeDetails = () => {
  const [data, setData] = useState([]);
  const [dataBuy, setDataBuy] = useState([]);
  const [dataSell, setDataSell] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { sellTable, buyTable } = useTableContent(dataBuy, dataSell);
  const navigate = useNavigate();
  const location = useLocation();

  const { date } = location.state || {};

  const dateSplitted = date.split("-");
  const dateObj = new Date(
    dateSplitted[0],
    +dateSplitted[1] - 1,
    dateSplitted[2],
  );

  const endOfDayObj = endOfDay(dateObj);

  const getTradesData = async () => {
    let body = {
      type: "trades",
      from: formatISO(new Date(dateObj)).slice(0, 19) + "Z",
      to: formatISO(new Date(endOfDayObj)).slice(0, 19) + "Z",
      groupBy: "user",
      currencies: ["BTC"],
    };

    const tradesDataSell = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      { ...body, subType: "sell" },
    );
    const tradesDataBuy = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      { ...body, subType: "buy" },
    );
    const summaryTradeData = {};
    Object.keys(tradesDataSell.grouped).forEach(
      (el) => (summaryTradeData[el] = {}),
    );
    Object.keys(tradesDataBuy.grouped).forEach(
      (el) => (summaryTradeData[el] = {}),
    );

    Object.keys(summaryTradeData).forEach((el) => {
      summaryTradeData[el]["sell"] = tradesDataSell.grouped[el];
      summaryTradeData[el]["buy"] = tradesDataBuy.grouped[el];
    });

    setData(summaryTradeData);

    setDataSell(tradesDataSell);
    setDataBuy(tradesDataBuy);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    getTradesData();
  }, []);

  if (isLoading) {
    return (
      <div className="deposit-details__loader">
        <MoonLoader
          color="#7236CD"
          loading={isLoading}
          cssOverride={loaderOverride}
          size={35}
        />
      </div>
    );
  }
  return (
    <div className="deposit-details__wrapper">
      <div className="deposit-details__content">
        <Header withLogo navigate={navigate} />
        <div className="deposit-details__top-block">
          <span className="deposit-details__label">{parseDate(date)}</span>
        </div>
        <div className="deposit-details__tables">
          <StatisticTable data={buyTable} />
          <StatisticTable data={sellTable} />
        </div>
        <div className="deposit__items-table">
          <TradeDetailsLegend />
          {data
            ? Object.entries(data)?.map((el) => (
                <TradeDetailsItem el={el} key={el[0]} />
              ))
            : null}
        </div>
      </div>
      <Footer navigate={navigate} />
    </div>
  );
};

export default TradeDetails;
