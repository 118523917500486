import React from "react";
import "./TradeDetailsLegend.scss";

const TradeDetailsLegend = () => {
  return (
    <div className="legend-trade-details__wrapper">
      <span className="legend-trade-details__element">BTC Buys (T/C)</span>
      <span className="legend-trade-details__element">BTC Sells (T/C)</span>
      <span className="legend-trade-details__element">User</span>
    </div>
  );
};

export default TradeDetailsLegend;
