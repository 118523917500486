import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import "./SendsDetailsItem.scss";

const SendsDetailsItem = ({ index, el }) => {
  const navigate = useNavigate();

  const wrapperClass = `sends-details-item__wrapper ${
    index % 2 === 0 ? "sends-details-item" : "sends-details-item__dark"
  }`;

  const item = el[1];
  const email = el[1]?.email;
  const paymentCount = item?.payments?.LN?.count ?? 0;
  const sendCashCount = item?.sendCash?.LN?.count ?? 0;
  const onChainCount = item?.onChain?.LN?.count ?? 0;
  const totalCount = paymentCount + sendCashCount + onChainCount ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="sends-details-item__element">
        <span>
          LN{" "}
          {cutWithoutRounding(
            calculateDecimal(
              calculateDecimal(
                item?.payments?.LN?.amount || "0",
                item?.sendCash?.LN?.amount || "0",
                "add",
              ) || "0",
              item?.onChain?.LN?.amount || "0",
              "add",
            ),
            8,
          )}
        </span>
        <span>{totalCount}</span>
      </span>
      <span className="sends-details-item__element">
        <span>LN {cutWithoutRounding(item?.payments?.LN?.amount || 0, 8)}</span>
        <span>{paymentCount}</span>
      </span>
      <span className="sends-details-item__element">
        <span>LN {cutWithoutRounding(item?.sendCash?.LN?.amount || 0, 8)}</span>
        <span>{sendCashCount}</span>
      </span>
      <span className="sends-details-item__element">
        <span>LN {cutWithoutRounding(item?.onChain?.LN?.amount || 0, 8)}</span>
        <span>{onChainCount}</span>
      </span>

      <span
        onClick={() => navigate(`/user/${el[0]}`)}
        className="deposit-item__element deposit-item__element-justify-center deposit-item__element-link"
      >
        {email}
      </span>
    </div>
  );
};

export default SendsDetailsItem;
