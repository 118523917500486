import React from "react";
import "./SendsLegend.scss";

const SendsLegend = () => {
  return (
    <div className="legend-sends__wrapper">
      <span className="legend-sends__element">Date</span>
      <span className="legend-sends__element">LN Sends (T/C)</span>
      <span className="legend-sends__element">Regular Sends (T/C)</span>
      <span className="legend-sends__element">Cash Sends (T/C)</span>
      <span className="legend-sends__element">On-Chain Sends (T/C)</span>
    </div>
  );
};

export default SendsLegend;
