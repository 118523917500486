const useTableContent = (pureData) => {
  const totalTable = [
    {
      key: "Total Registrations",
      value: `${pureData?.total?.registered ?? 0}`,
    },
  ];
  const pendingTable = [
    {
      key: "Total KYC Pending",
      value: `${pureData?.total?.pending ?? 0}`,
    },
  ];
  const passedTable = [
    {
      key: "Total KYC Passed",
      value: `${pureData?.total?.active ?? 0}`,
    },
  ];

  return {
    totalTable,
    pendingTable,
    passedTable,
  };
};

export default useTableContent;
