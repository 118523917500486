import React from "react";
import "./CommandsLegend.scss";

const CommandsLegend = () => {
  return (
    <div className="legend-command__wrapper">
      <span className="legend-command__element">Command</span>
      <span className="legend-command__element legend-command__element-bigger">Code</span>
      <span className="legend-command__element">Type</span>
    </div>
  );
};

export default CommandsLegend;
