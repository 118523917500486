import React from "react";
import { useNavigate } from "react-router-dom";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import parseDate from "../../utils/parseDate";
import Button from "../Button/Button";
import "./TradeItem.scss";

const TradeItem = ({ index, el }) => {
  const navigate = useNavigate();

  const wrapperClass = `trade-item__wrapper ${
    index % 2 === 0 ? "trade-item" : "trade-item__dark"
  }`;

  const item = el[1];
  const buyCount = item?.buy?.BTC?.count ?? 0;
  const sellCount = item?.sell?.BTC?.count ?? 0;

  return (
    <div className={wrapperClass}>
      <span className="trade-item__element trade-item__element-justify-center">
        {parseDate(el[0])}
      </span>
      <span className="trade-item__element">
        <span>
        {`BTC ${cutWithoutRounding(item?.buy?.BTC?.amount || 0, 8)}`}
        </span>
        <span>{buyCount || "0"}</span>
      </span>
      <span className="trade-item__element">
        <span>
          {`BTC ${cutWithoutRounding(item?.sell?.BTC?.amount || 0, 8)}`}
        </span>
        <span>{sellCount || "0"}</span>
      </span>

      <Button
        wrapperStyles="trade-item__button"
        labelStyles="trade-item__label"
        label="Details"
        onPress={() =>
          navigate(`/tradeDetails`, {
            state: {
              date: el[0],
            },
          })
        }
      />
    </div>
  );
};

export default TradeItem;
