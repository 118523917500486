import React, { useEffect, useState } from "react";
import "./SendsDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { formatISO, endOfDay } from "date-fns";
import webSocketService from "../../services/WebSocketService";
import StatisticTable from "../../components/StatisticTable/StatisticTable";
import useTableContent from "./hooks/useTableContent";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import parseDate from "../../utils/parseDate";
import SendsDetailsLegend from "../../components/SendsDetailsLegend/SendsDetailsLegend";
import SendsDetailsItem from "../../components/SendsDetailsItem/SendsDetailsItem";

const SendsDetails = () => {
  const [data, setData] = useState([]);
  const [onChain, setOnChain] = useState([]);
  const [sendCash, setSendCash] = useState([]);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    totalTable,
    totalRegularTable,
    totalCashSendsTable,
    totalOnChainTable,
  } = useTableContent(onChain, sendCash, payments);
  const navigate = useNavigate();
  const location = useLocation();

  const { date } = location.state || {};

  const dateSplitted = date.split("-");
  const dateObj = new Date(
    dateSplitted[0],
    +dateSplitted[1] - 1,
    dateSplitted[2],
  );

  const endOfDayObj = endOfDay(dateObj);

  const getLNData = async (from, to) => {
    let body = {
      type: "lightning",
      from: formatISO(new Date(dateObj)).slice(0, 19) + "Z",
      to: formatISO(new Date(endOfDayObj)).slice(0, 19) + "Z",
      groupBy: "user",
      currencies: ["USD", "BTC"],
    };

    const onChainData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      { ...body, subType: "sendOnChain" },
    );
    const sendCashData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      {
        ...body,
        subType: "sendCash",
      },
    );
    const paymentsData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      {
        ...body,
        subType: "payments",
      },
    );

    const summarySendsData = {};
    if (onChainData?.grouped) {
      Object.keys(onChainData?.grouped).forEach((el) => {
        if (!summarySendsData[el]) {
          summarySendsData[el] = {};
        }
        if (!summarySendsData[el].email && onChainData.grouped[el]?.email) {
          summarySendsData[el].email = onChainData.grouped[el]?.email;
        }
        summarySendsData[el].onChain = {
          ...onChainData.grouped[el]?.data,
        };
      });
    }
    if (sendCashData?.grouped) {
      Object.keys(sendCashData?.grouped).forEach((el) => {
        if (!summarySendsData[el]) {
          summarySendsData[el] = {};
        }
        if (!summarySendsData[el].email && sendCashData.grouped[el]?.email) {
          summarySendsData[el].email = sendCashData.grouped[el]?.email;
        }

        summarySendsData[el].sendCash = {
          ...sendCashData.grouped[el]?.data,
        };
      });
    }
    if (paymentsData?.grouped) {
      Object.keys(paymentsData?.grouped).forEach((el) => {
        if (!summarySendsData[el]) {
          summarySendsData[el] = {};
        }
        if (!summarySendsData[el].email && paymentsData.grouped[el]?.email) {
          summarySendsData[el].email = paymentsData.grouped[el]?.email;
        }
        summarySendsData[el].payments = {
          ...paymentsData.grouped[el]?.data,
        };
      });
    }

    if (typeof totals === "string") {
      setData([]);
    } else {
      setData(summarySendsData);
      setOnChain(onChainData);
      setSendCash(sendCashData);
      setPayments(paymentsData);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    getLNData();
  }, []);

  if (isLoading) {
    return (
      <div className="sends-details__loader">
        <MoonLoader
          color="#7236CD"
          loading={isLoading}
          cssOverride={loaderOverride}
          size={35}
        />
      </div>
    );
  }
  return (
    <div className="sends-details__wrapper">
      <div className="sends-details__content">
        <Header withLogo navigate={navigate} />
        <div className="sends-details__top-block">
          <span className="sends-details__label">{parseDate(date)}</span>
        </div>
        <div className="sends-details__tables">
          <StatisticTable data={totalTable} />
          <StatisticTable data={totalRegularTable} />
          <StatisticTable data={totalCashSendsTable} />
          <StatisticTable data={totalOnChainTable} />
        </div>

        <div className="sends-details__items-table">
          <SendsDetailsLegend />
          {data
            ? Object.entries(data)?.map((el) => (
                <SendsDetailsItem el={el} key={el[0]} />
              ))
            : null}
        </div>
      </div>
      <Footer navigate={navigate} />
    </div>
  );
};

export default SendsDetails;
