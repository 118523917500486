import {
  endOfMonth,
  formatISO,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { useState } from "react";
import webSocketService from "../../../../../services/WebSocketService";

const useDeposit = () => {
  const filterTypeSaved = localStorage.getItem("filterTypeDeposits");
  const fromSaved = localStorage.getItem("fromDeposits");
  const toSaved = localStorage.getItem("toDeposits");
  const savedSelectorValue = filterTypeSaved || "past7";
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const [selectorValue, setSelectorValue] = useState(savedSelectorValue);
  const [showRangePicker, setShowRangePicker] = useState(false);

  const getDepositsData = async (from, to) => {
    let body = {
      type: "deposits",
      from: formatISO(new Date(from)).slice(0, 19) + "Z",
      to: formatISO(new Date(to)).slice(0, 19) + "Z",
      groupBy: "date",
      currencies: ["USD", "BTC"],
    };

    const depositsData = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      body,
    );

    if (typeof totals === "string") {
      setData([]);
    } else {
      localStorage.setItem("filterTypeDeposits", selectorValue);
      localStorage.setItem("fromDeposits", from);
      localStorage.setItem("toDeposits", to);

      setData(depositsData);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const getDateRange = (value) => {
    const today = new Date();
    today.setMinutes(today.getMinutes() + today.getTimezoneOffset());
    let startDate, endDate;

    switch (value) {
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = today;
        break;
      case "lastMonth":
        const lastMonthDate = subMonths(today, 1);
        startDate = startOfMonth(lastMonthDate);
        endDate = endOfMonth(lastMonthDate);
        break;
      case "past7":
        startDate = subDays(today, 7);
        endDate = today;
        break;
      case "past30":
        startDate = subDays(today, 30);
        endDate = today;
        break;
      case "past90":
        startDate = subDays(today, 90);
        endDate = today;
        break;
      case "all":
        startDate = new Date(2023, 0, 1);
        endDate = today;
        break;
      case "customized":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = null;
        endDate = null;
    }
    setFromDate(startDate);
    setToDate(endDate);
    return { startDate, endDate };
  };
  return {
    filterTypeSaved,
    fromSaved,
    toSaved,
    fromDate,
    toDate,
    isLoaded,
    selectorValue,
    isLoading,
    data,
    showRangePicker,
    setSelectorValue,
    getDepositsData,
    setIsLoaded,
    setShowRangePicker,
    getDateRange,
    setFromDate,
    setToDate,
  };
};

export default useDeposit;
