import React from "react";
import "./KycDetailsLegend.scss";

const KycDetailsLegend = () => {
  return (
    <div className="legend-kyc-details__wrapper">
      <span className="legend-kyc-details__element">Status</span>
      <span className="legend-kyc-details__element">User</span>
    </div>
  );
};

export default KycDetailsLegend;
