import React, { useEffect } from "react";
import "./User.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import ActionsUser from "../../components/ActionsUser/ActionsUser";
import LegendUserPage from "../../components/LegendUserPage/LegendUserPage";
import UserInfoItem from "../../components/UserInfoItem/UserInfoItem";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import hideSensetive from "../../utils/hideSensitive";
import TransactionsStats from "../../components/TransactionsStats/TransactionsStats";
import { MoonLoader } from "react-spinners";
import CreditUserModal from "../../components/CreditUserModal/CreditUserModal";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import useUserData from "../../hooks/useUserData";
import ChangeGroupModal from "../../components/ChangeGroupModal/ChangeGroupModal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import parseDate from "../../utils/parseDate";
import UserType from "../../components/UserType/UserType";
import UserIcon from "../../assets/svgs/userIcon.svg";
import GoogleIcon from "../../assets/svgs/googleIcon.svg";
import AppleIcon from "../../assets/svgs/appleIcon.svg";
import parseTime from "../../utils/parseTime";
import IPItem from "../../components/IPItem/IPItem";
import UserComands from "../../components/UserComands/UserComands";

const User = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const {
    getUserData,
    loading,
    referredData,
    transactionsData,
    hideDocNumber,
    referrer,
    showCreditModal,
    userData,
    setShowCreditModal,
    setHideDocNumber,
    transfersData,
    setShowChangeGroupModal,
    showChangeGroupModal,
    limitsData,
    limits,
    tokensData,
    onShowMore,
    onShowLess,
    prompts,
  } = useUserData(userId);

  useEffect(() => {
    getUserData();
  }, [userId]);

  const getKYCDocs = () => {
    if (userData?.personal?.documents) {
      return `${capitalizeFirstLetter(
        userData?.personal?.documents[0]?.type,
      )} ${hideSensetive(
        userData?.personal?.documents[0]?.number,
        hideDocNumber,
      )}`;
    }
    return "";
  };

  const renderLoginMethodIcon = (method) => {
    switch (method) {
      case "signinwithapple":
        return AppleIcon;

      case "google":
        return GoogleIcon;

      case "internal":
        return UserIcon;

      default:
        return UserIcon;
    }
  };

  return !loading ? (
    <div className="user__wrapper">
      <Header navigate={navigate} withLogo />
      <div className="user__top-block">
        <span className="user__name">{`@${userData?.personal?.firstName} ${userData?.personal?.lastName}`}</span>
        <div className="user__under-info">
          <UserType type={userData?.type} />
          <div className="user__last">
            <span className="user__last__text">
              Last activity {parseDate(userData?.last?.timestamp)}
              {" - "}
              {parseTime(userData?.last?.timestamp)}
            </span>
          </div>
          <div className="user__last">
            <span className="user__last__text">
              Last IP address {userData?.last?.address}
            </span>
          </div>

          <img
            src={renderLoginMethodIcon(userData?.last?.login)}
            alt="arrow"
            className="user__last__icon"
          />
        </div>
      </div>
      <ActionsUser
        setShowCreditModal={setShowCreditModal}
        setShowChangeGroupModal={setShowChangeGroupModal}
        userData={userData}
      />
      <LegendUserPage />
      <div className="user__info-wrapper user__dark">
        <span className="user__element">{userData?.personal?.email}</span>
        <span className="user__element">
          {userData?.created && parseDate(userData?.created)}
        </span>
        <span className="user__element user__element-bigger">
          {userData?.referral?.codes?.[0]?.code}
        </span>
        <span className="user__element">
          {userData?.session?.device?.model}
        </span>
        <span className="user__element">{userData?.session?.build}</span>
      </div>
      <span className="user__label">Limits</span>
      <div className="user__details">
        <UserInfoItem
          title="USD Withdrawal"
          value={`$${cutWithoutRounding(
            limits?.fiat?.daily,
            2,
          )}/day $${cutWithoutRounding(limits?.fiat?.weekly, 2)}/week`}
          wrapperClass={"user__limits-wrapper"}
        />
        <UserInfoItem
          title="BTC Send"
          value={`$${cutWithoutRounding(
            limits?.crypto?.daily,
            2,
          )}/day $${cutWithoutRounding(limits?.crypto?.weekly, 2)}/week`}
          wrapperClass={"user__limits-wrapper"}
        />
      </div>
      <span className="user__label">Customer details</span>
      <div className="user__details">
        <UserInfoItem
          title="State:"
          value={userData?.personal?.address?.region}
        />
        <UserInfoItem
          title="Country:"
          value={userData?.personal?.address?.country}
        />
        <UserInfoItem
          title="KYC Status:"
          value={capitalizeFirstLetter(userData?.custodials?.crypto?.status)}
        />
        <UserInfoItem
          hideFunc={() => setHideDocNumber(!hideDocNumber)}
          title="KYC Docs:"
          value={getKYCDocs()}
        />
        <UserInfoItem
          title="EDD Status:"
          value={
            !!prompts?.[0]?.status ? prompts?.[0]?.status?.toUpperCase() : "-"
          }
        />
      </div>
      <span className="user__label">Balances</span>
      <div className="user__details">
        <UserInfoItem
          title="Bitcoin Wallet"
          value={`BTC ${cutWithoutRounding(userData?.balances?.BTC, 8)}`}
        />
        <UserInfoItem
          title="USD Wallet"
          value={`$ ${cutWithoutRounding(userData?.balances?.USD, 2)}`}
        />
        <UserInfoItem
          title="LN Wallet BTC"
          value={`BTC ${cutWithoutRounding(userData?.balances?.LN, 8)}`}
        />
      </div>
      <span className="user__label">Referrals</span>
      <div className="user__details-ref">
        <UserInfoItem
          title="Referred by:"
          isMultipleValues
          values={[
            { userId: referrer?.userId, email: referrer?.personal?.email },
          ]}
          wrapperClass="user__referred-by"
        />
        <UserInfoItem
          bigContainer
          title="Referred:"
          isMultipleValues
          values={referredData}
          wrapperClass="user__referred-by"
          itemClass="user__referred-item"
        />
        <UserInfoItem
          title="Referral rewards"
          isSingleTitle
          onPress={() => navigate(`/transactions/${userId}/referral`)}
        />
      </div>
      <span className="user__label">Transactions overview</span>
      <TransactionsStats
        transactions={transactionsData}
        transfersData={transfersData}
        userId={userId}
      />
      <UserInfoItem
        title="All transactions"
        isSingleTitle
        onPress={() => navigate(`/transactions/${userId}`)}
      />
      <span className="user__label user__marginTop">History IP</span>
      <div className="user__ips">
        {tokensData?.map((el) => (
          <IPItem
            address={el?.address}
            lastUsed={el?.lastUsed}
            key={el?.address + el?.lastUsed}
          />
        ))}
        <span className="user__ip-button" onClick={onShowMore}>
          Show more
        </span>
        {tokensData?.length > 5 ? (
          <span className="user__ip-button" onClick={onShowLess}>
            Show less
          </span>
        ) : null}
      </div>
      <span className="user__label user__marginTop">User commands</span>

      <UserComands userId={userId} />
      <Footer navigate={navigate} />
      {showCreditModal && (
        <CreditUserModal
          showCreditModal={showCreditModal}
          setShowCreditModal={setShowCreditModal}
          userId={userId}
        />
      )}
      {showChangeGroupModal && (
        <ChangeGroupModal
          showModal={showChangeGroupModal}
          setShowModal={setShowChangeGroupModal}
          onSuccess={getUserData}
          limitsData={limitsData}
          userId={userId}
        />
      )}
    </div>
  ) : (
    <div className="user__loader">
      <MoonLoader
        color="#7236CD"
        loading={loading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default User;
