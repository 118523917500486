import {
  endOfMonth,
  formatISO,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { useState } from "react";
import webSocketService from "../../../../../services/WebSocketService";

const useTrade = () => {
  const filterTypeSaved = localStorage.getItem("filterTypeTrade");
  const fromSaved = localStorage.getItem("fromTrade");
  const toSaved = localStorage.getItem("toTrade");
  const savedSelectorValue = filterTypeSaved || "past7";
  const [data, setData] = useState([]);
  const [dataSell, setDataSell] = useState([]);
  const [dataBuy, setDataBuy] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const [selectorValue, setSelectorValue] = useState(savedSelectorValue);
  const [showRangePicker, setShowRangePicker] = useState(false);

  const getTradesData = async (from, to) => {
    let body = {
      type: "trades",

      from: formatISO(new Date(from)).slice(0, 19) + "Z",
      to: formatISO(new Date(to)).slice(0, 19) + "Z",
      groupBy: "date",
      currencies: ["BTC"],
    };

    const tradesDataSell = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      { ...body, subType: "sell" },
    );
    const tradesDataBuy = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      { ...body, subType: "buy" },
    );
    const summaryTradeData = {};
    Object.keys(tradesDataSell.grouped).forEach(
      (el) => (summaryTradeData[el] = {}),
    );
    Object.keys(tradesDataBuy.grouped).forEach(
      (el) => (summaryTradeData[el] = {}),
    );

    Object.keys(summaryTradeData).forEach((el) => {
      summaryTradeData[el]["sell"] = tradesDataSell.grouped[el]?.data;
      summaryTradeData[el]["buy"] = tradesDataBuy.grouped[el]?.data;
    });

    setData(summaryTradeData);

    localStorage.setItem("filterTypeTrade", selectorValue);
    localStorage.setItem("fromTrade", from);
    localStorage.setItem("toTrade", to);

    setDataSell(tradesDataSell);
    setDataBuy(tradesDataBuy);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const getDateRange = (value) => {
    const today = new Date();
    today.setMinutes(today.getMinutes() + today.getTimezoneOffset());
    let startDate, endDate;

    switch (value) {
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = today;
        break;
      case "lastMonth":
        const lastMonthDate = subMonths(today, 1);
        startDate = startOfMonth(lastMonthDate);
        endDate = endOfMonth(lastMonthDate);
        break;
      case "past7":
        startDate = subDays(today, 7);
        endDate = today;
        break;
      case "past30":
        startDate = subDays(today, 30);
        endDate = today;
        break;
      case "past90":
        startDate = subDays(today, 90);
        endDate = today;
        break;
      case "all":
        startDate = new Date(2023, 0, 1);
        endDate = today;
        break;
      case "customized":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = null;
        endDate = null;
    }
    setFromDate(startDate);
    setToDate(endDate);
    return { startDate, endDate };
  };
  return {
    filterTypeSaved,
    fromSaved,
    toSaved,
    fromDate,
    toDate,
    isLoaded,
    selectorValue,
    isLoading,
    data,
    showRangePicker,
    dataSell,
    dataBuy,
    setSelectorValue,
    getTradesData,
    setIsLoaded,
    setShowRangePicker,
    getDateRange,
    setFromDate,
    setToDate,
  };
};

export default useTrade;
