import { cutWithoutRounding } from "../../../utils/cutWithoutRounding";
import { showNumberWithDelimiters } from "../../../utils/showNumberWithDelimiters";

const useTableContent = (dataBuy, dataSell) => {
  const buyTable = [
    {
      key: "Total BTC Buys",
      value: `BTC ${cutWithoutRounding(
        dataBuy?.total?.data?.BTC?.amount || 0,
        8,
      )}`,
      subValue: `≈ $${showNumberWithDelimiters(
        cutWithoutRounding(dataBuy?.total?.data?.BTC?.value, 2),
      )}`,
    },
    {
      key: "BTC Buys Count",
      value: dataBuy?.total?.data?.BTC?.count,
    },
  ];
  const sellTable = [
    {
      key: "Total BTC Sells",
      value: `BTC ${cutWithoutRounding(
        dataSell?.total?.data?.BTC?.amount || 0,
        8,
      )}`,
      subValue: `≈ $${showNumberWithDelimiters(
        cutWithoutRounding(dataSell?.total?.data?.BTC?.value, 2),
      )}`,
    },
    {
      key: "BTC Sells Count",
      value: dataSell?.total?.data?.BTC?.count,
    },
  ];

  return {
    sellTable,
    buyTable,
  };
};

export default useTableContent;
