import React, { useEffect, useState } from "react";
import "./MonetizationDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BalanceItem from "../../components/BalanceItem/BalanceItem";
import MonetizationDetailsLegend from "../../components/MonetizationDetailsLegend/MonetizationDetailsLegend";
import MonetizationDetailsItem from "../../components/MonetizationDetailsItem/MonetizationDetailsItem";
import { calculateDecimal } from "../../utils/calculateDecimal";
import parseDate from "../../utils/parseDate";
import { showNumberWithDelimiters } from "../../utils/showNumberWithDelimiters";

const MonetizationDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const { item, balancesData, uniqueUsers, totalFees, date } =
    location.state || {};

  const getItemsByUsers = () => {
    const result = [];
    uniqueUsers?.forEach((userId) => {
      result.push(item?.transactions?.filter((el) => el.user.id === userId));
    });
    return result;
  };

  useEffect(() => {
    setData(getItemsByUsers());
  }, []);

  const calculateFeeValue = (transactions, feature) => {
    const filteredByFeatureValues = transactions?.filter(
      (el) => el?.feature === feature,
    );
    const amountBtc = filteredByFeatureValues
      ?.filter((el) => el?.currency === "BTC")
      ?.reduce((acc, el) => {
        return calculateDecimal(el?.amount, acc, "add");
      }, 0);
    const amountUsd = filteredByFeatureValues
      ?.filter((el) => el?.currency === "USD")
      ?.reduce((acc, el) => {
        return calculateDecimal(el?.amount, acc, "add");
      }, 0);
    const totalValue = calculateDecimal(
      calculateDecimal(
        amountBtc,
        balancesData?.sfox?.treasury?.currencies?.BTC?.rate,
        "multiply",
      ),
      amountUsd,
      "add",
    );
    return totalValue;
  };

  return (
    <div className="monetiz-details__wrapper">
      <div className="monetiz-details__content">
        <Header withLogo navigate={navigate} />

        <div className="monetiz-details__top-block">
          <span className="monetiz-details__label">{parseDate(date)}</span>
        </div>

        <div className="monetiz-details__total">
          <BalanceItem
            title="Total Fees"
            balance={`$${showNumberWithDelimiters(totalFees)}`}
          />
        </div>
        <div className="monetiz-details__items-table">
          <MonetizationDetailsLegend />
          {data?.map((el) => (
            <MonetizationDetailsItem
              userId={el[0]?.user?.id}
              transactionsLength={el?.length}
              withdraw={calculateFeeValue(el, "WITHDRAW")}
              rfq={calculateFeeValue(el, "RFQ")}
              total={calculateDecimal(
                calculateFeeValue(el, "RFQ"),
                calculateFeeValue(el, "WITHDRAW"),
                "add",
              )}
              el={el}
            />
          ))}
        </div>
      </div>
      <Footer navigate={navigate} />
    </div>
  );
};

export default MonetizationDetails;
